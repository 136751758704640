<template>
  <ValidationObserver ref="userDetails">
<b-row class="match-height" v-if="user">
    <b-col>
        <b-overlay
            :show = "this.loading"
            spinner-variant="primary"
            rounded="sm"
            style="max-width: 100%;"
        >
            <b-card>
                <b-form>
                    <b-row>
                
                    <!-- email -->
                    <b-col lg="7">
                        <b-form-group
                        label="Email"
                        label-for="v-email"
                        >
                        <ValidationProvider #default="{ errors }" name="Email" mode="lazy" rules="required|email">
                        <b-form-input
                            id="v-email"
                            placeholder="Email"
                            v-model="user.email"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                        </b-form-group>
                    </b-col>

                    <!-- password -->
                    <b-col lg="7">
                        <b-form-group
                        :label="$t('password')"
                        label-for="v-password"
                        >
                        
                        <b-form-input
                            id="v-password"
                            value="**************"
                            disabled
                        />
                        <b-form-text><a href="#" v-b-modal.password-change-modal class="change-password">{{ $t('changePassword') }}</a></b-form-text>
                        
                        </b-form-group>
                    </b-col>

                    <!-- first name -->
                    <b-col lg="7">
                        <b-form-group
                        :label="$t('firstname')"
                        label-for="v-first-name"
                        >
                        <ValidationProvider #default="{ errors }" name="First Name" mode="lazy" rules="required">
                        <b-form-input
                            id="v-first-name"
                            :placeholder="$t('firstname')"
                            :state="errors.length > 0 ? false:null"
                            v-model="user.firstname"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                        </b-form-group>
                    </b-col>

                    <!-- last name -->
                    <b-col lg="7">
                        <b-form-group
                        :label="$t('lastname')"
                        label-for="v-email"
                        >
                        <ValidationProvider #default="{ errors }" name="Last Name" mode="lazy" rules="required">
                        <b-form-input
                            id="v-last-name"
                            :placeholder="$t('lastname')"
                            v-model="user.lastname"
                            :state="errors.length > 0 ? false:null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        </ValidationProvider>
                        </b-form-group>
                    </b-col>                   
                    </b-row>
                </b-form>
            </b-card>

            <div> 
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    @click="submitData"
                    variant="primary"
                    class="mr-1"
                    >
                    {{ $t('submit') }}
                </b-button>
            </div>
        </b-overlay>
    </b-col>
</b-row>

<b-modal
      id="password-change-modal"
      :title="$t('changePassword')"
    >
        <ValidationObserver ref="changePassword">
            <b-card-text>
                <!-- old password -->
                <b-col cols="12">
                    <b-form-group
                    :label="$t('oldPassword')"
                    label-for="v-old-password"
                    >
                    <ValidationProvider #default="{ errors }" name="Old password" mode="lazy" rules="required|password">
                    <b-form-input
                        id="v-old-password"
                        type="password"
                        v-model="oldPassword"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                    </b-form-group>
                </b-col>

                <!-- email -->
                <b-col cols="12">
                    <b-form-group
                    :label="$t('newPassword')"
                    label-for="v-new-password"
                    >
                    <ValidationProvider #default="{ errors }" name="New password" vid="new_password" mode="lazy" rules="required|password">
                    <b-form-input
                        id="v-new-password"
                        type="password"
                        v-model="newPassword"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                    </b-form-group>
                </b-col>

                <!-- email -->
                <b-col cols="12">
                    <b-form-group
                    :label="$t('confirmPassword')"
                    label-for="v-confirm-password"
                    >
                    <ValidationProvider #default="{ errors }" name="Confirm password" mode="lazy" rules="required|confirmed:new_password">
                    <b-form-input
                        id="v-confirm-password"
                        type="password"
                        v-model="confirmPassword"
                        :state="errors.length > 0 ? false:null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                    </b-form-group>
                </b-col>

            </b-card-text>
        </ValidationObserver>

        <template #modal-footer="{ hide }">
            <b-button
                type="cancel"
                variant="outline-secondary"
                @click="hide('forget')"
            >
                {{ $t('cancel') }}
            </b-button>
            <b-button
                type="submit"
                variant="primary"
                @click="submitPasswordChange"
            >
                {{ $t('submit') }}
            </b-button>
        </template>
    </b-modal>

</ValidationObserver>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton, BCard, BFormText, VBModal, BCardText, BSpinner, BOverlay
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import moduleUser from '@/store/user/moduleUser.js'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, email, confirmed } from '@supplier-interface-validations'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BSpinner,
    BOverlay,
    BCard,
    ValidationObserver,
    ValidationProvider,
    ToastificationContent,
    BFormText,
    VBModal,
    BCardText
  },
  directives: {
    Ripple,
  },
  data() {
    return {
    
      loading: true,
      user: null,
      oldPassword: null,
      newPassword: null,
      confirmPassword: null,

    }
  },
  methods: {
      submitData() {
        this.loading = true;
        this.$refs.userDetails.validate().then(success => {
            if (success) {
                this.$store.dispatch('user/editUserDetails', {
                    'email': this.user.email,
                    'firstname': this.user.firstname,
                    'lastname': this.user.lastname
                })
                .then( (userDetails) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t("User details successfully changed!"),
                            icon: 'CheckIcon',
                            variant: 'success',
                        },
                    })
                })
                .catch( (error) => {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: this.$t(error.message),
                            icon: 'AlertTriangleIcon',
                            variant: 'danger',
                        },
                    })
                })
                .finally(()=>{this.loading = false})
            }
        })
    },
    submitPasswordChange(){
        this.loading = true;
        this.$refs.changePassword.validate().then(success => {
            if (success) {
                this.$store.dispatch('user/changePassword', {
                    old_password: this.oldPassword,
                    new_password: this.newPassword,
                    confirm_password: this.confirmPassword
                })
                .then(
                    () => {
                        this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t("Password successfully changed!"),
                                icon: 'CheckIcon',
                                variant: 'success',
                            },
                        })
                        // Clear passwords and close modal
                        this.oldPassword = null
                        this.newPassword = null
                        this.confirmPassword = null
                        this.$bvModal.hide('password-change-modal')
                    }
                )
                .catch( (error) => {
                    this.$toast({
                            component: ToastificationContent,
                            props: {
                                title: this.$t(error.message),
                                icon: 'AlertTriangleIcon',
                                variant: 'danger',
                            },
                        })
                })
                .finally(()=>{this.loading = false})
            }
        })
    }
  },
  created() {
    if (!store.hasModule('user')) store.registerModule('user', moduleUser)

    this.loading = true;
    this.user = this.$store.state.user
    if(typeof user == "undefined" || user == null){
     
      this.$store.dispatch("user/getUserDetails")
        .then( (user) => {
          this.user = user;
        })
        .catch(() => {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$("Error fetching user"),
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
        .finally(()=>{this.loading = false})
    }
  }
}
</script>

<style lang="scss">

.change-password {
    font-size: 0.8rem;
    color: #00BCD4;
}

</style>