import { extend, localize } from 'vee-validate'
import { required, email, min, max, confirmed, numeric, double} from 'vee-validate/dist/rules'
import el from 'vee-validate/dist/locale/el.json';

localize({
  gr: {
    messages: {
      "required": "Το πεδίο είναι υποχρεωτικό",
      "email": "Το email πρέπει να είναι έγκυρο",
      "password": "Ο κωδικός πρέπει να περιλαμβάνει τουλάχιστον έναν αριθμό, έναν ειδικό χαρακτήρα και να αποτελείται από 8 χαρακτήρες ή περισσότερους",
      "min": "Το πεδίο πρέπει να αποτελείται από τουλάχιστον {length} χαρακτήρες",
      "max": "Το πεδίο δεν πρέπει να υπερβαίνει τους {length} χαρακτήρες",
      "size": "Το μέγεθος του αρχείου δεν πρέπει να υπερβαίνει τα {size}KB"
    }
  }
});
localize('gr')	

extend('required', required)

extend('email', email)

extend('min', min)

extend('max', max)

extend('password', {
  validate(value) {
    if(value.length < 8){
      this.message = this.$t('Password must be at least 8 characters long')
      return false
    }
    else if(!value.match(/[A-Za-z]/) || !value.match(/[0-9]/) || !value.match(/[!#$%&()*+,-.:;<=>?@[\]^_`{|}~]/)){
      this.message = this.$t('Password should contain at least 1 latin character, 1 number and 1 special character')
      return false
    }
    return true
  }
});

extend('confirmed', {
  ...confirmed,
  message: 'Password confirmation does not match'
});

extend('price', {
  validate: value => { 
      if (typeof value != "string") return false
      return (!isNaN(value) && !isNaN(parseFloat(value))) && value > 0
    },
  message: 'Price should be positive and have up to two decimals separated by dot'
})

export * from '@validations'

